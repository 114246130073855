















































import { PageBase } from "@/core/models/shared";
import { JSONToCSVConvertor } from '@/assets/scripts/helper';
import { Component, Prop, Watch } from "vue-property-decorator";
import { AlunoService } from "@/core/services/geral";

@Component
export default class ConfiguracaoCracha extends PageBase {
    
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    
    alunoService: AlunoService = new AlunoService();
    overlay: boolean = false;
    
    filtro: any = {
        inicio: '',
        fim: ''
    }

    $refs!: {
        form: HTMLFormElement
    }

    GerarRelatorio(){        
        if (this.$refs.form.validate()) {
            let routeLink = this.$router.resolve({name:"impressaoAniversariantes",
                query:{
                    inicio: this.filtro.inicio,
                    fim: this.filtro.fim
                }
            });

            window.open(routeLink.href,'_blank');
        }
    }

    GerarCSV(){
        if (this.$refs.form.validate()) {
            this.alunoService.Aniversariantes(this.filtro.inicio, this.filtro.fim).then(
                res =>{
                    JSONToCSVConvertor(res.data, "Aniversariantes", true);
                },
                err =>{
                    this.$swal('Aviso',err.response.data,'error')
                }
            ).finally(() => {
                this.overlay = false;
            })
        }
    }

    LimparFiltros(){
        
    }
}
